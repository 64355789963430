import * as React from "react"
import DocumentationContent from "../../components/DocumentationContent"
import DocumentationSidebar from "../../components/DocumentationSidebar"
import PropTypes from 'prop-types'
import { graphql, Link } from 'gatsby'
import Layout from '../../components/Layout'
import Footer from '../../components/Footer'
import Header from '../../components/Header'
import {FullSlideshowLightboxExample, SlideshowLightboxExampleDoc, SlideshowLightboxExample, 
  PropsDoc, ThemesDoc} from "../../components/Examples";
import Content, { HTMLContent } from '../../components/Content'
import Documentation from '../../components/Documentation'
import {SlideshowLightbox, Image} from "lightbox.js-react";
import { CopyBlock, CodeBlock, atomOneDark, solarizedDark, xt256 } from "react-code-blocks";
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { oneDark } from 'react-syntax-highlighter/dist/esm/styles/prism';

export default function NextJsGuideComponent () {
  const images = [{title: "Photon", src: "https://source.unsplash.com/600x400/?pastel+cloud"}, 
  {title: "Inversion", src: "https://source.unsplash.com/500x400/?pastel+clouds"},
  {title: "Legion", src: "https://source.unsplash.com/600x400/?pastel+sky"},
  {title: "Inversion", src: "https://source.unsplash.com/600x400/?pastel"},
  {title: "Twenty Neue", src: "https://source.unsplash.com/600x400/?sky+pastel"},
  {title: "Geometrik", src: "https://source.unsplash.com/600x400/?dreamy+clouds"},         
  {title: "Achtung", src: "https://source.unsplash.com/600x400/?pastel+fruit"},];
  const showLineNumbers = true;
  const wrapLines = true;
  const codeBlock = false;

  return <Layout>
  <Header />
  <section className="section section--gradient font-inter bg_white">
  
      <div className="pt-4">
      <main className="flex-grow">

          <section>
          <div className="max-w-6xl mx-auto px-2 sm:px-2">
              <div className="pt-32 pb-12 md:pt-40 md:pb-20">

              {/* Main content */}
              <div className="md:flex md:justify-between" data-sticky-container>

                  <DocumentationSidebar />

                  <div className="md:flex-grow documentation font-inter">

                    {/* <Header /> */}
                    <div className="text-lg text-gray-600">
                      <h1 className="h1 text-gray-900 mb-4 font-bold">Next.js Guide</h1>

                      <p className="mb-8">
                      Lightbox.js also supports Next.js, and due to popular demand, new functionality has been added to support server-side rendering frameworks such as Next.js and Gatsby.
                      Additionally, if you'd like to use the Next.js <code>Image</code> component, this feature is also available too.

                      </p>

                      <p>If you have any queries, don't hesitate to <Link to="/contact" class="underline text-blue-700">contact us</Link>, and we'll be in touch!</p>

                      <p className="mb-8">
                        In this guide, we're going to take a look at how to set up a lightbox with Next.js.
                      </p>

                    <h3 id="import" className="h3 text-gray-900 font-bold text-3xl" style={{ scrollMarginTop: '100px' }}>Import</h3>

                    <p>
                    Firstly, import the components required as well as the CSS file for this library:
                    </p>

                    <div className="mb-8">
                        <SyntaxHighlighter language="jsx" style={oneDark}  className="mb-8 codeBlock">
                        {`import 'lightbox.js-react/dist/index.css'\nimport {SlideshowLightbox} from 'lightbox.js-react'`}
                        </SyntaxHighlighter>
                    </div>

                    <h4 className="h4 text-gray-900 ">Adding a license key</h4>

                    <p>Next, a license key will need to be initialized in order to use Lightbox.js. If you require a license key
                      you can purchase an individual or team license <a className="text-underline text-blue-600" href="/pricing">here</a>,
                      but if your project is open-source, just <a className="text-underline text-blue-600" href="/contact/">contact us</a> to get a free 
                      license key. </p>

                      <p className="flex mt-8 items-center text-base px-3 py-4 text-gray-800 bg-blue-100 border border-blue-200 rounded mb-8">
                                <svg className="w-4 h-4 fill-current text-blue-500 flex-shrink-0 mr-2" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M8 0a8 8 0 100 16A8 8 0 008 0zm2.629 11.618L8 10.236l-2.629 1.382.5-2.927-2.124-2.073 2.939-.427L8 3.528l1.314 2.663 2.939.427-2.126 2.073.502 2.927z" fill="#338CF5" fillRule="nonzero" />
                                </svg>
                                <span>If you wish to purchase a license, you can get a 20% off discount with the coupon code OFFER20 at checkout.</span>
                    </p>            


                      <p>The license key can be initialized like so:</p>

                      <SyntaxHighlighter language="jsx" style={oneDark}  className="mb-12 codeBlock">
                      {`useEffect(() => {
    initLightboxJS("Insert License key", "Insert plan type here");
});`}
                      </SyntaxHighlighter>

                    <p>
                      The two plan types are <code>individual</code> and <code>team</code>.
                    </p>

                    <h3 id="usage" className="text-gray-900 mb-4 text-3xl font-semibold" style={{ scrollMarginTop: '100px' }}>Usage</h3>
                   <p>Create an array of images: </p>
                   <div className="mb-8">
                        <SyntaxHighlighter language="js" style={oneDark} className="mb-8 codeBlock">
                        {`const images = [
  {
    src: 'https://source.unsplash.com/sQZ_A17cufs/549x711',
    alt: 'Mechanical keyboard with white keycaps.',
  },
  {
    src: 'https://source.unsplash.com/rsAeSMzOX9Y/768x512',
    alt: 'Mechanical keyboard with white, pastel green and red keycaps.',
  },
  {
    src: 'https://source.unsplash.com/Z6SXt1v5tP8/768x512',
    alt: 'Mechanical keyboard with white, pastel pink, yellow and red keycaps.',
  },
]

`}
                        </SyntaxHighlighter>
                    </div>

                    <p>
                    Next, wrap the images you wish to include in the slideshow gallery within a SlideshowLightbox   
                     component as shown below. 
                    </p>
                    
                    
                    <div className="mb-8">
                        <SyntaxHighlighter language="jsx" style={oneDark} className="mb-8 codeBlock">
                        {`<SlideshowLightbox lightboxIdentifier="lightbox1" framework="next" images={images}>
        {images.map((image) => (
            <Image
              src={image.src}
              alt={image.alt}
              height={500}
              width={500}
              data-lightboxjs="lightbox1"
              quality={80}
            />
        ))}
</SlideshowLightbox>`}
                        </SyntaxHighlighter>
                    </div>

                    <p>
                    To support Next.js, 3 extra props are required:

                    </p>

                    <ul className="list-disc list ml-8">
                          <li><b>images:</b> An array of images containing src and alt attributes
                          </li>
                          <li> <b>lightboxIdentifier:</b> A string value, should be equal to the data-lightboxjs data attribute passed to each image.</li>
                          <li><b>framework:</b> Set to "next" for Next.js.</li>

                    </ul>

                    <h4>Data attributes</h4>
                    <p>
                    To identify the image elements, a <code>data-lightboxjs</code> data attribute should be added to each Next.js Image used. This should be equal to the lightboxIdentifier prop. The same group of images would have the same lightbox identifier attribute. If you wanted to add a 2nd lightbox to the page, then a different lightbox identifier would be used etc.</p>

                    <p>The lightbox looks for any images that have this data attribute and then includes them in the lightbox. So if you have nested components, the images can be identified by the lightbox as a result.
                    </p>

                    <ThemesDoc />

                    <article className="mb-8 mt-8">
                      <h2 className="h2 text-gray-900 mb-4 text-3xl font-semibold">Customization</h2>

                      <h4>Theme</h4>
                      <p className="list_desc">
                        If you'd like to change the theme of the lightbox, this can be done by passing a theme name 
                        to the <code>theme</code> prop, with the options including: "lightbox", "night", "day"
                      </p>

                      <div className="mb-8">
                            <SyntaxHighlighter language="jsx" style={oneDark} className="codeBlock">
                            {`<SlideshowLightbox theme="lightbox">
...
</SlideshowLightbox>`}
                            </SyntaxHighlighter>
                        </div>

                        <h4>Full Screen Images</h4>
                      <p className="list_desc">
                        If you'd like the images to take up the screen's full available width and height, simply set the 
                        <code>fullScreen</code> prop to <code>true</code>. The default is <code>false</code>.
                      </p>

                      <div className="mb-8">
                            <SyntaxHighlighter language="jsx" style={oneDark} className="codeBlock">
                            {`<SlideshowLightbox fullScreen={true}>
...
</SlideshowLightbox>`}
                            </SyntaxHighlighter>
                        </div>

                        <h4>Show Controls</h4>
                      <p>
                        If you'd like to remove the controls (in the top-right corner) of the lightbox, 
                        this can be done by setting the
                        <code>showControls</code> prop to <code>false</code>:
                      </p>

                      <div className="mb-8">
                            <SyntaxHighlighter language="jsx" style={oneDark} className="codeBlock">
                            {`<SlideshowLightbox showControls={false}>
...
</SlideshowLightbox>`}
                            </SyntaxHighlighter>
                        </div>

                      <h4>Background Color</h4>
                      <p>
                        If you'd like to customize the background color of the lightbox, this can be done by passing a color 
                        to the <code>color</code> prop, as a RGB, RGBA, HEX or CSS color name value:
                      </p>

                      <div className="mb-8">
                            <SyntaxHighlighter language="jsx" style={oneDark} className="codeBlock">
                            {`<SlideshowLightbox backgroundColor="white">
...
</SlideshowLightbox>`}
                            </SyntaxHighlighter>
                        </div>
                      
                      <h4>Icon Color</h4>
                      <p>
                        The icon colors can also be specified through the `iconColor` prop, and the color can be a RGB, 
                        RGBA, HEX or CSS color name value.
                      </p>

                        <div className="mb-8">
                            <SyntaxHighlighter language="jsx" style={oneDark} className="codeBlock">
                            {`<SlideshowLightbox iconColor="silver">
...
</SlideshowLightbox>`}
                            </SyntaxHighlighter>
                        </div>

                    <h4>Thumbnail border</h4>
                    <p>
                        Each thumbnail contains a border which can be customized or removed entirely (by setting the border color 
                        to transparent). The border can be customized through specifying a color value for the `thumbnailBorder` prop, 
                        and the color can be a RGB, RGBA, HEX or CSS color name value.
                    </p>

                      <div className="mb-8">
                            <SyntaxHighlighter language="jsx" style={oneDark} className="codeBlock">
                            {`<SlideshowLightbox thumbnailBorder="silver">
...
</SlideshowLightbox>`}
                            </SyntaxHighlighter>
                      </div>

                    <h4>Show Thumbnails</h4>
                    <p>
                        If you would like to remove thumbnails entirely from the lightbox, you can do so by setting the 
                        `showThumbnails` prop to `false`.
                    </p>

                      <div className="mb-8">
                            <SyntaxHighlighter language="jsx" style={oneDark} className="codeBlock">
                            {`<SlideshowLightbox showThumbnails="false">
...
</SlideshowLightbox>`}
                            </SyntaxHighlighter>
                      </div>


                      <h4>Image Animation</h4>
                      <p className="list-desc">
                        The animation type can be specified through the `imgAnimation` prop, and the two values include:
                      </p>

                        <ul className="list list-disc ml-8">
                          <li>imgMotion</li>
                          <li>fade</li> 
                        </ul>

                        <div className="mb-8">
                            <SyntaxHighlighter language="jsx" style={oneDark} className="codeBlock">
                            {`<SlideshowLightbox imgAnimation="fade">
...
</SlideshowLightbox>`}
                            </SyntaxHighlighter>
                        </div>

                        <h4>Slideshow Interval</h4>
                      <p>
                        When the slideshow is playing, the amount of time between slideshow transitions can be specified through the 
                        `slideshowInterval` prop in <b>milliseconds</b>.
                      </p>

   
                        <div className="mb-8">
                            <SyntaxHighlighter language="jsx" style={oneDark} className="codeBlock">
                            {`<SlideshowLightbox slideshowInterval={1600}>
...
</SlideshowLightbox>`}
                            </SyntaxHighlighter>
                        </div>

                        <h4>Animate Thumbnails</h4>
                      <p>
                        By default, the thumbnails are animated in and out of the DOM using a fade-in effect. If you would 
                        prefer to switch this animation off, this can be done so by setting the `animateThumbnails` prop 
                        to `false`.
                      </p>

   
                        <div className="mb-8">
                            <SyntaxHighlighter language="jsx" style={oneDark} className="codeBlock">
                            {`<SlideshowLightbox animateThumbnails={false}>
...
</SlideshowLightbox>`}
                            </SyntaxHighlighter>
                        </div>

      </article>
      
      <PropsDoc />

    </div>

    {/* Related content */}
    <div className="mt-8">
      <h3 className="h3 mb-8 font-semibold">Related</h3>
      <Link className="flex justify-between items-center p-4 rounded border border-gray-200 
      transition duration-300 ease-in-out bg-white shadow-md hover:shadow-lg mb-4" to="/docs/Image">
        <div>
          <div className="text-normal font-medium mb-1">Image component</div>
          <div className="text-sm text-gray-600">If you'd prefer to display a single image within a lightbox, be sure 
          to check out the Image component.</div>
        </div>
        <svg className="w-4 h-4 fill-current text-blue-600 flex-shrink-0 ml-6" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
          <path d="M9.3 14.7l-1.4-1.4L12.2 9H0V7h12.2L7.9 2.7l1.4-1.4L16 8z" />
        </svg>
      </Link>
      <a className="flex justify-between items-center p-4 rounded border border-gray-200 transition 
      duration-300 ease-in-out bg-white shadow-md hover:shadow-lg mb-4" href="https://github.com/silvia-odwyer/lightbox.js">
        <div>
          <div className="text-normal font-medium mb-1">Feature request</div>
          <div className="text-sm text-gray-600">Want to request a new feature? Be sure to open an issue in our GitHub repository!</div>
        </div>
        <svg className="w-4 h-4 fill-current text-blue-600 flex-shrink-0 ml-6" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
          <path d="M9.3 14.7l-1.4-1.4L12.2 9H0V7h12.2L7.9 2.7l1.4-1.4L16 8z" />
        </svg>
      </a>
      <hr className="w-full h-px pt-px bg-gray-200 border-0 mt-8" />
    </div>

    {/* <Feedback /> */}

  </div>

              </div>

              </div>
          </div>
          </section>

          </main>
      </div>
          
  </section>
    <Footer overrideDarkTheme={true} />
  </Layout>
  
}